/*
  A stylesheet customizing app (custom renderers)
*/

@import url(https://fonts.googleapis.com/css?family=Roboto);

body{
  font-family: "Roboto", "Sans Serif";
  /* font-size: 18pt; */
}

html,
body {
  min-height: 100%;
  margin: 0;
}

body {
  padding: 30px;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  box-sizing: border-box;
}
/*  */

#myTable {
  width: 100%;
}

.rotated-th {
	/**
	* Since the rotated text is taken out of the DOM flow (position: absolute), we
	* need to artificially consume vertical space so that the rotated headers
	* don't overlap with the content above the table.
	*/
	height: 110px ;
	position: relative ;
}
/**
* When an element is transform rotated, it still takes up the amount of space that
* it would have if not rotated. As such, I'm making the label "position: absolute"
* so that it doesn't take up any space ("absolute" takes it out of the DOM flow).
* Instead, I'm deferring the space allocation to the parent DIV.
*/
.rotated-th__label {
	bottom: 5px ;
	left: 50% ;
	position: absolute ;
	transform: rotate( -45deg ) ;
	transform-origin: center left ;
	white-space: nowrap ;
}

#myTable td.yes {
  color: green;
  background-color: lightgreen;
}

/*  */

.mystyle { width: 100%; font-size: 11pt; font-family: Arial;border-collapse: collapse; border: 1px solid silver;}
.mystyle td, th {padding: 5px; width: 100px; overflow-wrap: anywhere;}
.mystyle tr:nth-child(even) {background: #E0E0E0;}
.mystyle tr:hover {background: silver;cursor: pointer;}
.mystyle th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
tr.mystyle th {
  background: black;
  color: white;
}
code {
  font-family: "Lucida Console", "Menlo", "Monaco", "Courier", monospace;
  color: crimson;						
  background-color: #f1f1f1;			
  padding: 2px;							
  font-size: 100%;						
}

.hidden {
  display: none;
}

label {
  margin-bottom: 10px;
  display: block;
}